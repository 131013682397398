import { Thunk } from "../utils";
import { toastSlice } from "../toast/slice";
import { VoiceAPI } from "./api";
import { voiceSlice } from "./slice";
import { AccessToken, EgressClient, EncodedFileOutput, EncodedFileType } from 'livekit-server-sdk';

export namespace VoiceThunks {
    export const generateSpeechThunk: Thunk<string, HTMLAudioElement> =
    (message: string) =>
        async (dispatch, getState) => {
            console.log("[VOICE THUNK] 🎬 generate speech");

            try {
                dispatch(voiceSlice.actions.setLoading(true));
                const response = await VoiceAPI.generateSpeech(message, dispatch);
                
                dispatch(voiceSlice.actions.setAudioData(response));

                const audio = new Audio("data:audio/wav;base64," + response);
                await audio.play();
                return audio;
            } catch (error: any) {
                logger.log("[VOICE THUNK] 🔴 generate speech:", error);
                dispatch(toastSlice.actions.setToast({
                    type: "error",
                    message: "Failed to generate speech. Please try again.",
                    error: error.message || "Unknown error occurred",
                    fatal: false
                }));
                throw error;
            } finally {
                dispatch(voiceSlice.actions.setLoading(false));
                logger.log("[VOICE THUNK] 🔚 generate speech complete");
            }
        };

    export const createRoomAndToken: Thunk<number | undefined, void> = (threadId) => async (dispatch, getState) => {
        try {
            dispatch(voiceSlice.actions.setLoading(true));

            // CHANGE OUT THIS LINE
            const roomName = 'voice_assistant_room_' + Math.random().toString(36).substring(2, 15);
            // const roomName = "voice_assistant_room_g382aknnpu"
            const participantName = 'user_' + Math.random().toString(36).substring(2, 15);

            logger.log("[VOICE THUNK] 🔑 create room and token");
            logger.log("roomName: ", roomName);
            // WARNING: This is not secure for production use
            const apiKey = process.env.REACT_APP_LIVEKIT_API_KEY;
            const apiSecret = process.env.REACT_APP_LIVEKIT_API_SECRET;
            const serverUrl = process.env.REACT_APP_LIVEKIT_SERVER_URL;

            if (!apiKey || !apiSecret || !serverUrl) {
                throw new Error("LiveKit API key, secret, or server URL is missing");
            }

            // Create the access token
            const at = new AccessToken(apiKey, apiSecret, {
                identity: participantName,
                ttl: 600, // 10 minutes in seconds
            });
            at.addGrant({ roomJoin: true, room: roomName });

            const generatedToken = at.toJwt();

            // Remove the EgressClient part for now
            // const egressClient = new EgressClient(serverUrl, apiKey, apiSecret);
            // ... (remove the egress-related code)

            dispatch(voiceSlice.actions.setConnectionDetails({
                serverUrl,
                roomName,
                participantName,
                participantToken: await generatedToken
            }));

        } catch (error: any) {
            logger.error("Failed to create room and token:", error);
            dispatch(toastSlice.actions.setToast({
                type: "error",
                message: "Failed to create room and token. Please try again.",
                error: error.message || "Unknown error occurred",
                fatal: false
            }));
        } finally {
            dispatch(voiceSlice.actions.setLoading(false));
        }
    };
}
