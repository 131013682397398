import React from 'react';
import { VideoTrack, GridLayout, ParticipantTile, useTracks } from "@livekit/components-react";
import { Track, RoomEvent } from 'livekit-client';
import { isTrackReference } from '@livekit/components-core';
import type { TrackReference } from '@livekit/components-core';

interface MediaDisplayProps {
  screenShareTrack: TrackReference | null;
}

export function MediaDisplay({ screenShareTrack }: MediaDisplayProps) {
  const tracks = useTracks(
    [
      { source: Track.Source.Camera, withPlaceholder: true },
      { source: Track.Source.ScreenShare, withPlaceholder: false },
    ],
    { updateOnlyOn: [RoomEvent.ActiveSpeakersChanged], onlySubscribed: false },
  ).filter((track) => {
    // Filter out agent tracks if needed
    const metadata = track.participant.metadata ? JSON.parse(track.participant.metadata) : {};
    return metadata.role !== 'agent';
  });

  const videoTracks = tracks
    .filter(isTrackReference)
    .filter((track) => track.publication.source === Track.Source.Camera);

  return (
    <div className="w-full h-full flex flex-col gap-4">
      {screenShareTrack && (
        <div className="flex-1 rounded-md overflow-hidden">
          <VideoTrack 
            trackRef={screenShareTrack} 
            className="w-full h-full object-contain"
          />
        </div>
      )}
      
      {videoTracks.length > 0 && (
        <div className={`${screenShareTrack ? 'h-1/3' : 'h-full'} rounded-md overflow-hidden`}>
          <GridLayout tracks={videoTracks} className="w-full h-full">
            <ParticipantTile />
          </GridLayout>
        </div>
      )}
    </div>
  );
} 