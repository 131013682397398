import React, { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import {
    VoiceAssistantControlBar,
    ControlBar,
    AgentState,
    DisconnectButton,
    Chat,
    GridLayout,
    ParticipantTile,
    FocusLayoutContainer,
    CarouselLayout,
    FocusLayout,
    LayoutContextProvider,
    RoomAudioRenderer,
    ConnectionStateToast,
    TrackReference,
} from "@livekit/components-react";
import * as Icons from "solar-icon-set";
import { useKrispNoiseFilter } from '@livekit/components-react/krisp';
import Button from '../../../grapes/atoms/button/Button';
import { Track, RoomEvent } from 'livekit-client';
import { isEqualTrackRef, isTrackReference, isWeb } from '@livekit/components-core';
import { usePinnedTracks, useTracks, useCreateLayoutContext } from '@livekit/components-react';
import type { WidgetState } from '@livekit/components-core';

interface PFControlBarProps {
    onConnectButtonClicked: () => void;
    agentState: AgentState;
    onScreenShareChange: (track: TrackReference | null) => void;
}

function PFControlBar({ onConnectButtonClicked, agentState, onScreenShareChange }: PFControlBarProps) {
    const krisp = useKrispNoiseFilter();
    const [widgetState, setWidgetState] = useState<WidgetState>({
        showChat: false,
        unreadMessages: 0,
        showSettings: false,
    });
    const lastAutoFocusedScreenShareTrack = React.useRef<any>(null);
    const layoutContext = useCreateLayoutContext();

    // Modify tracks to only get screen share tracks
    const tracks = useTracks(
        [
            { source: Track.Source.ScreenShare, withPlaceholder: false },  // Only get screen share tracks
        ],
        { updateOnlyOn: [RoomEvent.ActiveSpeakersChanged], onlySubscribed: false },
    );

    const screenShareTracks = tracks
        .filter(isTrackReference)
        .filter((track) => track.publication.source === Track.Source.ScreenShare);

    const focusTrack = usePinnedTracks(layoutContext)?.[0];

    // Screen share auto-focus effect
    useEffect(() => {
        const currentTrack = screenShareTracks.length > 0 ? screenShareTracks[0] : null;
        onScreenShareChange(currentTrack);
        
        if (currentTrack && lastAutoFocusedScreenShareTrack.current === null) {
            layoutContext.pin.dispatch?.({ msg: 'set_pin', trackReference: currentTrack });
            lastAutoFocusedScreenShareTrack.current = currentTrack;
        } else if (
            lastAutoFocusedScreenShareTrack.current &&
            !screenShareTracks.some(track =>
                track.publication.trackSid === lastAutoFocusedScreenShareTrack.current?.publication?.trackSid)
        ) {
            layoutContext.pin.dispatch?.({ msg: 'clear_pin' });
            lastAutoFocusedScreenShareTrack.current = null;
        }
    }, [screenShareTracks, layoutContext, onScreenShareChange]);

    useEffect(() => {
        krisp.setNoiseFilterEnabled(true);
    }, []);

    return (
        <div className="relative justify-center mb-[90px] flex items-center">
            {agentState === "disconnected" && (
                <motion.div
                    initial={{ opacity: 0, top: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0, top: "-10px" }}
                    transition={{ duration: 1, ease: [0.09, 1.04, 0.245, 1.055] }}
                    className="absolute left-1/2 -translate-x-1/2"
                >
                    <Button
                        variant="primary"
                        onClick={() => onConnectButtonClicked()}
                        className="uppercase"
                    >
                        Start a conversation
                    </Button>
                </motion.div>
            )}
            {agentState !== "disconnected" && agentState !== "connecting" && (
                <>
                    <div className="flex justify-center mb-[-60px]">
                        <VoiceAssistantControlBar controls={{ leave: false }} />
                        <ControlBar variation="verbose" controls={{ camera: true, microphone: false, screenShare: true }} />
                    </div>
                    <RoomAudioRenderer />
                    <ConnectionStateToast />
                </>
            )}
        </div>
    );
}

export default PFControlBar;