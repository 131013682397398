import React, { useCallback, useEffect, useState } from 'react';
import { formatChatMessageLinks, LiveKitRoom, RoomAudioRenderer, VideoConference } from "@livekit/components-react";
import '@livekit/components-styles';
import { MediaDeviceFailure } from 'livekit-client';
import { SimpleVoiceAssistant } from '../../utils/components/ducks/voice/components/simpleVoiceAssistant';
import { NoAgentNotification } from '../../utils/components/ducks/voice/components/noAgentNotification';
import PFControlBar from '../../utils/components/ducks/voice/components/pfControlBar';
import { TranscriptionDisplay } from '../../utils/components/ducks/voice/components/TranscriptionDisplay';
import { useSelector } from 'react-redux';
import { RootState } from '../../utils/init/reduxInit';
import { useVoice } from '../../utils/components/ducks/voice/export';
import VoiceNavbar from '../../utils/components/ducks/voice/components/voiceNavbar';
import type { TrackReference } from '@livekit/components-core';
import { MediaDisplay } from '../../utils/components/ducks/voice/components/MediaDisplay';

const VoicePage = () => {
  const { thunks, actions } = useVoice();
  const { connectionDetails, agentState } = useSelector((state: RootState) => state.voice.data);
  const [screenShareTrack, setScreenShareTrack] = useState<TrackReference | null>(null);

  const onConnectButtonClicked = useCallback(() => {
    thunks.createRoomAndToken(undefined);
  }, [thunks]);

  const onMediaDeviceFailure = (failure?: MediaDeviceFailure) => {
    logger.error("Media device failure:", failure);
  };

  return (
    <main data-lk-theme="default" className="grid content-center">
      <VoiceNavbar />
      <LiveKitRoom
        token={connectionDetails?.participantToken}
        serverUrl={connectionDetails?.serverUrl}
        connect={!!connectionDetails}
        audio={true}
        video={false}
        onMediaDeviceFailure={onMediaDeviceFailure}
        onDisconnected={() => {
          logger.log("Disconnected from LiveKit");
          actions.setConnectionDetails(undefined);
        }}
        onConnected={() => {
          logger.log("Connected to LiveKit");
        }}
        onError={(error) => {
          logger.error("LiveKit error:", error);
        }}
      >
        {/* <VideoConference />  */}
        <RoomAudioRenderer />
        <NoAgentNotification state={agentState} />
        
        <div className="flex flex-col md:flex-row gap-4 w-full max-w-[90vw] mx-auto">
          <div className={`flex-1 ${screenShareTrack ? 'md:w-1/3 my-auto' : 'w-full'}`}>
            <SimpleVoiceAssistant onStateChange={(state) => actions.setAgentState(state)} />
          </div>
          
          <div className="flex-1 md:w-2/3">
            <MediaDisplay screenShareTrack={screenShareTrack} />
          </div>
        </div>

        <PFControlBar 
          onConnectButtonClicked={onConnectButtonClicked} 
          agentState={agentState}
          onScreenShareChange={setScreenShareTrack}
        />
        <TranscriptionDisplay />
      </LiveKitRoom>
    </main>
  );
}

export default VoicePage;